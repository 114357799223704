import React from "react"
import { Link } from "gatsby"
import { Box, Button, Paper, Typography } from "@material-ui/core"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import FiltreGaleri from "../components/global/carousel/filtregaleri"
import FiltreKullanimAlani from "../components/global/filtre/kullanimalani"
import ProjeyeGorePaslanmazFiltre from "../components/global/gatsby-image/projeyegorepaslanmazfiltre"
import PaslanmazFiltreGaleri from "../components/global/carousel/paslanmazfiltregaleri"
import Seo from "../components/global/seo/seo.jsx"
const PaslanmazFiltreImalati = () => {
  return (
    <Layout>
      <Seo
        title="Paslanmaz Filtre İmalatı - Paslanmaz Filtre Erez Kaynak " 
        description="Paslanmaz Filtre ve Elek İmalatı Firmamız proje çizimine yada numuneye göre müşterilerimizin istekleri doğrultusunda paslanmaz filtre elek imalatı yapmaktadır.Göz aralıkları mikronlar ile ölçülebilen mesh telleri kullanılarak istediğiniz ölçü, numune yada projeye göre paslanmaz filtre imalatını"
        keywords="filtre imalatı,elek imalatı,paslanmaz filtre,paslanmaz elek,filitre elek teli,filtre paslanmaz elek,yağ filtresi,makina filtresi,emiş filtresi,filtre elek istanbul,filtre elek kullanım alanları,filitre kullanım alanları,filre kullanım alanları"
        url="paslanmaz-filtre-imalati"
      />
      <Box my={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <Typography component="h1" variant="h4">
              <Box textAlign="center" fontWeight={500} mt={3}>
                Paslanmaz Filtre ve Elek İmalatı
              </Box>
            </Typography>
            <Lead>
              Firmamız proje çizimine yada numuneye göre müşterilerimizin
              istekleri doğrultusunda <span>paslanmaz filtre elek imalatı</span>{" "}
              yapmaktadır.
            </Lead>
            <Lead>
              Göz aralıkları mikronlar ile ölçülebilen mesh telleri kullanılarak
              istediğiniz ölçü, numune ya da projeye göre{" "}
              <span>paslanmaz filtre imalatını</span> gerçekleştirmekteyiz.
            </Lead>
            <Lead>
              Detaylı bilgi ya da fiyat teklifi almak için bizi arayınız.
            </Lead>
            <Box textAlign="center" pb={3}>
              <Link to="/iletisim" style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="secondary">
                  İLETİŞİM BİLGİLERİ
                </Button>
              </Link>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box my={3}>
        <PaslanmazFiltreGaleri />
      </Box>
      <Box my={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <FiltreKullanimAlani align="center" />
            <br />
            <ProjeyeGorePaslanmazFiltre
              elevation
              imgUrl="filtre1fluid"
              imgAlt="Projeye Göre Paslanmaz Filtre"
            />
          </Box>
        </Paper>
      </Box>
      <Box my={3}>
        <FiltreGaleri />
      </Box>
      <Box my={3}>
        <ProjeyeGorePaslanmazFiltre
          elevation
          card
          imgUrl="filtre3fluid"
          imgAlt="Projeye Göre Paslanmaz Filtre"
        />
      </Box>
    </Layout>
  )
}
export default PaslanmazFiltreImalati
